var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "fill-width align-self-start"
  }, [_c('v-card', {
    staticClass: "d-flex align-center justify-center fill-width",
    attrs: {
      "color": "primary",
      "flat": "",
      "height": "70",
      "tile": ""
    }
  }, [_c('a', {
    staticClass: "text-center pa-5",
    on: {
      "click": function click($event) {
        return _vm.$router.push({
          name: 'dashboard'
        });
      }
    }
  }, [_c('img', {
    staticClass: "max-h-70px",
    attrs: {
      "src": "/media/logos/logo_light_155x30.png",
      "alt": ""
    }
  })])])], 1), _c('v-container', [_c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "9"
    }
  }, [_c('manage-settings', {
    attrs: {
      "from-page": ""
    }
  })], 1), _c('v-col', {
    staticClass: "text-center",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('v-card', {
    staticClass: "py-3",
    staticStyle: {
      "border-radius": "10%"
    },
    attrs: {
      "rounded": "",
      "flat": "",
      "color": "primary"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "white",
      "size": "100"
    }
  }, [_vm._v("mdi-rocket")]), _c('v-card-text', [_c('div', {
    staticClass: "py-3 titleFont bold-text white--text"
  }, [_c('div', {
    staticClass: "py-3"
  }, [_vm._v("Share your app now")])]), _c('v-btn', {
    staticClass: "font-weight-bold primary--text",
    attrs: {
      "depressed": "",
      "color": "white"
    },
    on: {
      "click": function click($event) {
        _vm.$router.push({
          name: 'dashboard'
        }).catch();
      }
    }
  }, [_vm._v("Access Portal")])], 1)], 1)], 1)], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "mt-10"
  }, [_c('Footer')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }